.img-card-boder {
    width: 100%;
    border: 1px solid #D5DBDB;
    border-radius: 3px;
}

.img-card-boder-selected {
    width: 100%;
    border: 1px solid #3498DB;
    border-radius: 3px;
    box-shadow: 0px 5px 13px -7px #3498DB, 5px 5px 15px 5px rgb(0 0 0 / 0%);
}

.img-card-boder:hover {
    box-shadow: 0px 5px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
}